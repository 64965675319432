<template>
  <div class="privacyRev-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_privacyRev"
        :hoverInfo="translations.labels.button_add_privacyRev_hover"
        :onClick="newItem"
      />
    </Action-panel>
    <TableList :is-ready="isPageReady" :headers="headers" :items="model" />
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/dom/ConfirmDialog";
import Vue from "vue";
import Component from "vue-class-component";
import { toastService } from "@/services/toastService.js";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";

@Component({
  inject: {
    privacyService: "privacyService",
  },
  components: {
    TableList,
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    ConfirmDialog
  },
})
export default class PrivacyRevList extends Vue {
  isPageReady = false;
  model = [];
  search = "";

  async loadData() {
    this.model = await this.privacyService.getRevisions(this.idPrivacy);
  }

  get idPrivacy() {
    return this.$route.params.idPrivacy;
  }

  init() {
    try {
      this.loadData();
      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  newItem() {
    this.$router.push(`/privacy/revisions/${this.idPrivacy}/new`);
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.privacy_rev_table_version,
        value: "version",
      },
      {
        text: this.translations.labels.privacy_rev_table_type,
        value: "type",
      },
      {
        text: this.translations.labels.privacy_rev_table_required,
        value: "isRequired",
        type: "boolean",
        itemValue: (item) => {
          return item.isRequired;
        },
      },
      {
        text: this.translations.labels.privacy_rev_table_published,
        value: "isPublished",
        type: "boolean",
        itemValue: (item) => {
          return item.isPublished;
        },
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        type: "link",
        formatValue: (item) => {
          return `/privacy/revisions/${this.idPrivacy}/edit/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
      {
        text: this.translations.labels.table_publish,
        value: "publish",
        type: "action",
        icon: "mdi-publish",
        isVisible: (item) => {
          return !item.isPublished;
        },
        onAction: (item) => {
          this.publish(item);
        },
      },
    ];
  }

  mounted() {
    this.init();
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.privacyRev_list
    );
  }

  async publish(item) {
    if (
      await this.$refs.confirm.open(
        this.translations.confirmDialog.title,
        this.translations.confirmDialog.publish_privacy_rev_message
      )
    ) {
      try {
        await this.privacyService.publishRev(this.idPrivacy,item.id);
        this.loadData();
      } catch (error) {
        toastService.error(this.translations.errors.somethingWrong);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.privacyRev-list {
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
